.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
}
.form {
  width: 100%;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
  margin-bottom: 1.1rem;
}
.form .form-group {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  box-sizing: border-box;
  z-index: 1;
}
.form .form-group .form-label {
  position: absolute;
  top: 0.44rem;
  left: 0;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  z-index: 4;
}
.form .form-group .form-label .icon {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
}
.form .form-group .form-label .icon-mobile {
  width: 0.24rem;
  background-image: url('../../assets/icon_denglu_shouji@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .form .form-group .form-label .icon-mobile {
    background-image: url('../../assets/icon_denglu_shouji@3x.png');
  }
}
.form .form-group .form-label .icon-img-code {
  height: 0.28rem;
  background-image: url('../../assets/icon_denglu_yanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .form .form-group .form-label .icon-img-code {
    background-image: url('../../assets/icon_denglu_yanzhengma@3x.png');
  }
}
.form .form-group .form-label .icon-password {
  width: 0.28rem;
  background-image: url('../../assets/icon_denglu_mima@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .form .form-group .form-label .icon-password {
    background-image: url('../../assets/icon_denglu_mima@3x.png');
  }
}
.form .form-group .form-label .icon-sms-code {
  background-image: url('../../assets/icon_denglu_shuruyanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .form .form-group .form-label .icon-sms-code {
    background-image: url('../../assets/icon_denglu_shuruyanzhengma@3x.png');
  }
}
.form .form-group .form-control {
  position: relative;
  width: 100%;
  height: 98%;
  padding-left: 0.48rem;
  border: none;
  box-sizing: border-box;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  transition: 0.3s;
  z-index: 3;
}
.form .form-group .form-control::-webkit-input-placeholder {
  color: #b3b3b3;
}
.form .form-group .img-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 2.4rem;
  height: 0.8rem;
  z-index: 4;
}
.form .form-group .img-box .img-code {
  width: 100%;
  height: 100%;
}
.form .form-group .send-btn,
.form .form-group .countdown {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1.68rem;
  height: 0.52rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.52rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.26rem;
  z-index: 4;
}
.form .form-group .send-btn {
  background: var(--main-color);
}
.form .form-group .send-btn:active {
  color: #fff;
  background-color: rgba(16, 143, 244, 0.7);
}
.form .form-group .countdown {
  background: #999;
}
.next-btn {
  width: 6.4rem;
  height: 0.88rem;
  margin: 0 auto;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  white-space: nowrap;
  background: var(--main-color);
  border-radius: 0.5rem;
}
.next-btn:active {
  opacity: 0.7;
}
