.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.nickname {
  padding: 0.2rem 0 0 0.6rem;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.6rem;
}
.page .img-bg {
  width: 100%;
  height: 3.64rem;
}
.page .tab-list {
  position: relative;
  width: 100%;
  height: 1rem;
  margin-bottom: 0.4rem;
  overflow: hidden;
}
.page .tab-list li {
  width: 50%;
  height: 100%;
  float: left;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.page .tab-list li .btn {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
  text-align: center;
}
.page .tab-list li.active .btn {
  font-size: 0.36rem;
  color: #333;
  line-height: 0.5rem;
  font-weight: bold;
}
.page .tab-list li.active .btn:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: block;
  width: 2rem;
  height: 0.04rem;
  background-color: var(--main-color);
  border-radius: 0.03rem;
}
.page .title {
  padding-left: 0.52rem;
  margin-bottom: 0.18rem;
  font-size: 0.48rem;
  color: #333;
  line-height: 0.66rem;
  font-weight: bold;
}
.page .form {
  width: 100%;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
}
.page .form .form-group {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  box-sizing: border-box;
  z-index: 1;
}
.page .form .form-group .form-label {
  position: absolute;
  top: 0.39rem;
  left: 0;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  z-index: 4;
}
.page .form .form-group .form-label .icon {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
}
.page .form .form-group .form-label .icon-mobile {
  width: 0.24rem;
  background-image: url('../../assets/icon_denglu_shouji@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .form .form-group .form-label .icon-mobile {
    background-image: url('../../assets/icon_denglu_shouji@3x.png');
  }
}
.page .form .form-group .form-label .icon-img-code {
  height: 0.28rem;
  background-image: url('../../assets/icon_denglu_yanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .form .form-group .form-label .icon-img-code {
    background-image: url('../../assets/icon_denglu_yanzhengma@3x.png');
  }
}
.page .form .form-group .form-label .icon-sms-code {
  background-image: url('../../assets/icon_denglu_shuruyanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .form .form-group .form-label .icon-sms-code {
    background-image: url('../../assets/icon_denglu_shuruyanzhengma@3x.png');
  }
}
.page .form .form-group .form-control {
  position: relative;
  width: 100%;
  height: 98%;
  padding-left: 0.48rem;
  border: none;
  box-sizing: border-box;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  transition: 0.3s;
  z-index: 3;
}
.page .form .form-group .form-control::-webkit-input-placeholder {
  color: #b3b3b3;
}
.page .form .form-group .img-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 2.4rem;
  height: 0.8rem;
  z-index: 4;
}
.page .form .form-group .img-box .img-code {
  width: 100%;
  height: 100%;
}
.page .form .form-group .send-btn,
.page .form .form-group .countdown {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1.68rem;
  height: 0.52rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.52rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.26rem;
  z-index: 4;
}
.page .form .form-group .send-btn {
  background: var(--main-color);
}
.page .form .form-group .send-btn:active {
  color: #fff;
  background-color: rgba(16, 143, 244, 0.7);
}
.page .form .form-group .countdown {
  background: #999;
}
.page .form-bottom {
  position: relative;
  display: flex;
  height: 0.34rem;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
  margin-top: 0.2rem;
  /*margin-bottom: 0.58rem;*/
}
.page .form-bottom .protocol-wrap {
  display: flex;
  align-items: center;
}
.page .form-bottom .protocol-wrap .icon-wrap {
  width: 0.28rem;
  height: 0.28rem;
  margin-right: 0.1rem;
}
.page .form-bottom .protocol-wrap .select-item {
  width: 0.28rem;
  height: 0.28rem;
  color: #999;
}
.page .form-bottom .protocol-wrap .selected-item {
  width: 0.28rem;
  height: 0.28rem;
  color: var(--main-color);
}
.page .form-bottom .protocol-wrap .gray-txt {
  margin-right: 0.1rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 100%;
}
.page .form-bottom .protocol-wrap .protocol {
  font-size: 0.28rem;
  color: var(--main-color);
  line-height: 0.4rem;
}
.page .submit-btn {
  width: 6.4rem;
  height: 0.88rem;
  margin: 0 auto;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 0.5rem;
  overflow: hidden;
}
.page .submit-btn:active {
  opacity: 0.7;
}
.page .alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  backface-visibility: hidden;
}
.page .alert-container .alert-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.page .protocol-alert .alert-content {
  top: 0.7rem;
}
.page .protocol-alert .alert-content .protocol-container {
  width: 6.7rem;
  height: 8.86rem;
  margin-bottom: 0.4rem;
  background-color: #fff;
  border-radius: 0.08rem;
  border: 0.04rem solid var(--main-color);
  overflow: hidden;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 6.5rem;
  height: 8.66rem;
  padding: 0.3rem 0.2rem;
  background-color: #fff;
  border-radius: 0.08rem;
  border: 0.04rem solid var(--main-color);
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .top-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.76rem;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .top-title .dot {
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  background-color: #999;
  border-radius: 50%;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .top-title .txt {
  margin: 0 0.2rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.44rem;
  letter-spacing: -0.02rem;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .protocol-title {
  margin-bottom: 0.6rem;
  font-size: 0.36rem;
  color: var(--main-color);
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
  font-weight: bold;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .protocol-content {
  max-height: 60%;
  font-size: 0.26rem;
  color: #666;
  line-height: 0.44rem;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.page .protocol-alert .alert-content .close-btn {
  width: 0.68rem;
  height: 0.68rem;
  margin: 0 auto;
  background-image: url('../../assets/icon_denglu_guanbi@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .protocol-alert .alert-content .close-btn {
    background-image: url('../../assets/icon_denglu_guanbi@3x.png');
  }
}
.page .login-text {
  text-align: center;
  width: 100%;
  margin-top: 0.3rem;
}
