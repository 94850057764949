.border-none[data-v-d54bb6fa]:before {
  display: none;
}
.border-none[data-v-d54bb6fa]:after {
  display: none;
}
.flex-fix[data-v-d54bb6fa] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-d54bb6fa]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-d54bb6fa] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-d54bb6fa] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-d54bb6fa] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-d54bb6fa] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-d54bb6fa] {
  *zoom: 1;
}
.clear-fix[data-v-d54bb6fa]:before,
.clear-fix[data-v-d54bb6fa]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-d54bb6fa] {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
}
.form[data-v-d54bb6fa] {
  width: 100%;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
}
.form .form-group[data-v-d54bb6fa] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
}
.form .form-group .form-label[data-v-d54bb6fa] {
  position: absolute;
  top: 0.44rem;
  left: 0;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  z-index: 4;
}
.form .form-group .form-label .icon[data-v-d54bb6fa] {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
}
.form .form-group .form-label .icon-password[data-v-d54bb6fa] {
  width: 0.28rem;
  background-image: url('../../assets/icon_denglu_mima@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.form .form-group .form-label .icon-password[data-v-d54bb6fa] {
    background-image: url('../../assets/icon_denglu_mima@3x.png');
}
}
.form .form-group .form-control[data-v-d54bb6fa] {
  position: relative;
  width: 100%;
  height: 98%;
  padding-left: 0.48rem;
  border: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
}
.form .form-group .form-control[data-v-d54bb6fa]::-webkit-input-placeholder {
  color: #b3b3b3;
}
.form-bottom[data-v-d54bb6fa] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.34rem;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
  margin-top: 0.2rem;
  margin-bottom: 1.7rem;
}
.form-bottom .tip[data-v-d54bb6fa] {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  white-space: nowrap;
}
.submit-btn[data-v-d54bb6fa] {
  width: 6.4rem;
  height: 0.88rem;
  margin: 0 auto;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 0.5rem;
  overflow: hidden;
}
.submit-btn[data-v-d54bb6fa]:active {
  opacity: 0.7;
}
