.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.agreement {
  height: 8.66rem;
}
.agreement .agreement-con {
  height: 8.2rem;
  overflow: auto;
}
.agreement .agreement-con p {
  margin-bottom: 0.2rem;
}
.agreement .agreement-con .protocol-title {
  text-align: center;
  margin-bottom: 0.6rem;
  font-size: 0.36rem;
  color: var(--main-color);
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
  font-weight: bold;
}
.agreement .agreement-con .top-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.76rem;
  text-align: center;
}
.agreement .agreement-con .top-title .dot {
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  background-color: #999;
  border-radius: 50%;
}
.agreement .agreement-con .top-title .txt {
  margin: 0 0.2rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.44rem;
  letter-spacing: -0.02rem;
}
