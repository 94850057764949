.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.table {
  /*此元素会作为块级表格来显示（类似 <table>），表格前后带有换行符。*/
  display: table;
  /*border-collapse:collapse;*/
  border-collapse: separate;
  border: 1px solid #ccc;
}
.table-caption {
  /*此元素会作为一个表格标题显示（类似 <caption>）*/
  display: table-caption;
  margin: 0;
  font-size: 16px;
}
.table-header-group {
  /*此元素会作为一个或多个行的分组来显示（类似 <thead>）。*/
  display: table-header-group;
  font-weight: bold;
  font-size: 0.01rem;
}
.table-row-group {
  /*此元素会作为一个或多个行的分组来显示（类似 <tbody>）。*/
  display: table-row-group;
  font-size: 0.01rem;
}
.table-footer-group {
  /*此元素会作为一个或多个行的分组来显示（类似 <tfoot>）。*/
  display: table-footer-group;
}
ul {
  list-style: none;
}
.table-row {
  /*此元素会作为一个表格行显示（类似 <tr>）。*/
  display: table-row;
}
#top {
  border-top: 1px solid #ccc;
}
.table-cell {
  /*此元素会作为一个表格单元格显示（类似 <td> 和 <th>）*/
  display: table-cell;
  padding: 4px;
  border: 1px solid #ccc;
  line-height: 1.5;
  border-left: 0px;
  border-top: 0px;
}
.table-cell:first-child {
  border-left: 1px solid #ccc;
}
.table-row-group .table-row:hover,
.table-footer-group .table-row:hover {
  background: #f6f6f6;
  color: green;
  font-weight: bold;
}
.table-column-group {
  /*此元素会作为一个或多个列的分组来显示（类似 <colgroup>）。*/
  display: table-column-group;
}
.table-column {
  /*此元素会作为一个单元格列显示（类似 <col>）*/
  display: table-column;
  width: 100px;
}
.agreement {
  height: 8.66rem;
}
.agreement .agreement-con {
  height: 8.2rem;
  overflow: auto;
}
.agreement .agreement-con p {
  margin-bottom: 0.2rem;
  line-height: 1.5;
}
.agreement .agreement-con .protocol-title {
  text-align: center;
  margin-bottom: 0.6rem;
  font-size: 0.36rem;
  color: var(--main-color);
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
  font-weight: bold;
}
.agreement .agreement-con .top-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.76rem;
  text-align: center;
}
.agreement .agreement-con .top-title .dot {
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  background-color: #999;
  border-radius: 50%;
}
.agreement .agreement-con .top-title .txt {
  margin: 0 0.2rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.44rem;
  letter-spacing: -0.02rem;
}
