.border-none[data-v-dfc7df78]:before {
  display: none;
}
.border-none[data-v-dfc7df78]:after {
  display: none;
}
.flex-fix[data-v-dfc7df78] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-dfc7df78]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-dfc7df78] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-dfc7df78] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-dfc7df78] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-dfc7df78] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-dfc7df78] {
  *zoom: 1;
}
.clear-fix[data-v-dfc7df78]:before,
.clear-fix[data-v-dfc7df78]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-dfc7df78] {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.6rem;
}
.page .img-bg[data-v-dfc7df78] {
  width: 100%;
  height: 3.64rem;
}
.page .tab-list[data-v-dfc7df78] {
  position: relative;
  width: 100%;
  height: 1rem;
  margin-bottom: 0.3rem;
  overflow: hidden;
}
.page .tab-list li[data-v-dfc7df78] {
  width: 50%;
  height: 100%;
  float: left;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.page .tab-list li .btn[data-v-dfc7df78] {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
  text-align: center;
}
.page .tab-list li.active .btn[data-v-dfc7df78] {
  font-size: 0.36rem;
  color: #333;
  line-height: 0.5rem;
  font-weight: bold;
}
.page .tab-list li.active .btn[data-v-dfc7df78]:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  display: block;
  width: 2rem;
  height: 0.04rem;
  background-color: var(--main-color);
  border-radius: 0.03rem;
}
.page .title[data-v-dfc7df78] {
  padding-left: 0.52rem;
  font-size: 0.4rem;
  color: #000000;
  font-weight: bold;
}
.page .form[data-v-dfc7df78] {
  padding: 0 0.56rem 0 0.54rem;
  margin-bottom: 0.2rem;
}
.page .form .cell[data-v-dfc7df78] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding-left: 0.52rem;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #e1e1e1;
}
.page .form .cell .icon[data-v-dfc7df78] {
  position: absolute;
  top: 0.44rem;
  left: 0;
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  z-index: 2;
}
.page .form .cell input[data-v-dfc7df78] {
  width: 100%;
  height: 0.4rem;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.page .form .cell input[data-v-dfc7df78]::-webkit-input-placeholder {
  color: #b3b3b3;
}
.page .form .cell .icon-mobile[data-v-dfc7df78] {
  width: 0.24rem;
  background-image: url('../../assets/icon_denglu_shouji@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .form .cell .icon-mobile[data-v-dfc7df78] {
    background-image: url('../../assets/icon_denglu_shouji@3x.png');
}
}
.page .form .cell .icon-password[data-v-dfc7df78] {
  width: 0.28rem;
  background-image: url('../../assets/icon_denglu_mima@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .form .cell .icon-password[data-v-dfc7df78] {
    background-image: url('../../assets/icon_denglu_mima@3x.png');
}
}
.page .form .cell .icon-img-code[data-v-dfc7df78] {
  height: 0.28rem;
  background-image: url('../../assets/icon_denglu_yanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .form .cell .icon-img-code[data-v-dfc7df78] {
    background-image: url('../../assets/icon_denglu_yanzhengma@3x.png');
}
}
.page .form .cell .icon-sms-code[data-v-dfc7df78] {
  background-image: url('../../assets/icon_denglu_shuruyanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .form .cell .icon-sms-code[data-v-dfc7df78] {
    background-image: url('../../assets/icon_denglu_shuruyanzhengma@3x.png');
}
}
.page .form .cell .img-box[data-v-dfc7df78] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 2.4rem;
  height: 0.8rem;
  z-index: 4;
}
.page .form .cell .img-box .img-code[data-v-dfc7df78] {
  width: 100%;
  height: 100%;
}
.page .form .cell .send-btn[data-v-dfc7df78],
.page .form .cell .countdown[data-v-dfc7df78] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 1.68rem;
  height: 0.52rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.52rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.26rem;
  z-index: 4;
}
.page .form .cell .send-btn[data-v-dfc7df78] {
  background: var(--main-color);
}
.page .form .cell .send-btn[data-v-dfc7df78]:active {
  color: #fff;
  background-color: rgba(16, 143, 244, 0.7);
}
.page .form .cell .countdown[data-v-dfc7df78] {
  background: #999;
}
.page .form-bottom[data-v-dfc7df78] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.34rem;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
  /*margin-bottom: 1.54rem;*/
}
.page .form-bottom .forget-pwd-link[data-v-dfc7df78] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.54rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.34rem;
  white-space: nowrap;
  z-index: 2;
}
.page .form-bottom .login-status[data-v-dfc7df78] {
  background-color: var(--main-color);
  width: 2.8rem;
  height: 0.5rem;
  line-height: 0.5rem;
  color: #ffffff;
  font-size: 0.3rem;
  text-align: center;
  border-radius: 0.5rem;
}
.page .submit-btn[data-v-dfc7df78] {
  width: 6.4rem;
  height: 0.88rem;
  margin: 0 auto;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 0.5rem;
  overflow: hidden;
}
.page .submit-btn[data-v-dfc7df78]:active {
  opacity: 0.7;
}
.page .wx-btn[data-v-dfc7df78] {
  background-color: #09bb07;
  margin-top: 10px;
}
.page .login-text[data-v-dfc7df78] {
  text-align: center;
  width: 100%;
  margin-top: 0.3rem;
}
.page .dadi-shape[data-v-dfc7df78] {
  margin-top: 1.5rem;
}
