.border-none[data-v-74636682]:before {
  display: none;
}
.border-none[data-v-74636682]:after {
  display: none;
}
.flex-fix[data-v-74636682] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-74636682]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-74636682] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-74636682] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-74636682] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-74636682] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-74636682] {
  *zoom: 1;
}
.clear-fix[data-v-74636682]:before,
.clear-fix[data-v-74636682]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.nickname[data-v-74636682] {
  padding: 0.2rem 0 0 0.6rem;
}
.page[data-v-74636682] {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.6rem;
}
.page .img-bg[data-v-74636682] {
  width: 100%;
  height: 3.64rem;
}
.page .tab-list[data-v-74636682] {
  position: relative;
  width: 100%;
  height: 1rem;
  margin-bottom: 0.4rem;
  overflow: hidden;
}
.page .tab-list li[data-v-74636682] {
  width: 50%;
  height: 100%;
  float: left;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.page .tab-list li .btn[data-v-74636682] {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
  text-align: center;
}
.page .tab-list li.active .btn[data-v-74636682] {
  font-size: 0.36rem;
  color: #333;
  line-height: 0.5rem;
  font-weight: bold;
}
.page .tab-list li.active .btn[data-v-74636682]:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  display: block;
  width: 2rem;
  height: 0.04rem;
  background-color: var(--main-color);
  border-radius: 0.03rem;
}
.page .title[data-v-74636682] {
  padding-left: 0.52rem;
  margin-bottom: 0.18rem;
  font-size: 0.48rem;
  color: #333;
  line-height: 0.66rem;
  font-weight: bold;
}
.page .form[data-v-74636682] {
  width: 100%;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
}
.page .form .form-group[data-v-74636682] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
}
.page .form .form-group .form-label[data-v-74636682] {
  position: absolute;
  top: 0.39rem;
  left: 0;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  z-index: 4;
}
.page .form .form-group .form-label .icon[data-v-74636682] {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
}
.page .form .form-group .form-label .icon-mobile[data-v-74636682] {
  width: 0.24rem;
  background-image: url('../../assets/icon_denglu_shouji@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .form .form-group .form-label .icon-mobile[data-v-74636682] {
    background-image: url('../../assets/icon_denglu_shouji@3x.png');
}
}
.page .form .form-group .form-label .icon-img-code[data-v-74636682] {
  height: 0.28rem;
  background-image: url('../../assets/icon_denglu_yanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .form .form-group .form-label .icon-img-code[data-v-74636682] {
    background-image: url('../../assets/icon_denglu_yanzhengma@3x.png');
}
}
.page .form .form-group .form-label .icon-sms-code[data-v-74636682] {
  background-image: url('../../assets/icon_denglu_shuruyanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .form .form-group .form-label .icon-sms-code[data-v-74636682] {
    background-image: url('../../assets/icon_denglu_shuruyanzhengma@3x.png');
}
}
.page .form .form-group .form-control[data-v-74636682] {
  position: relative;
  width: 100%;
  height: 98%;
  padding-left: 0.48rem;
  border: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
}
.page .form .form-group .form-control[data-v-74636682]::-webkit-input-placeholder {
  color: #b3b3b3;
}
.page .form .form-group .img-box[data-v-74636682] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 2.4rem;
  height: 0.8rem;
  z-index: 4;
}
.page .form .form-group .img-box .img-code[data-v-74636682] {
  width: 100%;
  height: 100%;
}
.page .form .form-group .send-btn[data-v-74636682],
.page .form .form-group .countdown[data-v-74636682] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 1.68rem;
  height: 0.52rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.52rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.26rem;
  z-index: 4;
}
.page .form .form-group .send-btn[data-v-74636682] {
  background: var(--main-color);
}
.page .form .form-group .send-btn[data-v-74636682]:active {
  color: #fff;
  background-color: rgba(16, 143, 244, 0.7);
}
.page .form .form-group .countdown[data-v-74636682] {
  background: #999;
}
.page .form-bottom[data-v-74636682] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.34rem;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
  margin-top: 0.2rem;
  /*margin-bottom: 0.58rem;*/
}
.page .form-bottom .protocol-wrap[data-v-74636682] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page .form-bottom .protocol-wrap .icon-wrap[data-v-74636682] {
  width: 0.28rem;
  height: 0.28rem;
  margin-right: 0.1rem;
}
.page .form-bottom .protocol-wrap .select-item[data-v-74636682] {
  width: 0.28rem;
  height: 0.28rem;
  color: #999;
}
.page .form-bottom .protocol-wrap .selected-item[data-v-74636682] {
  width: 0.28rem;
  height: 0.28rem;
  color: var(--main-color);
}
.page .form-bottom .protocol-wrap .gray-txt[data-v-74636682] {
  margin-right: 0.1rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 100%;
}
.page .form-bottom .protocol-wrap .protocol[data-v-74636682] {
  font-size: 0.28rem;
  color: var(--main-color);
  line-height: 0.4rem;
}
.page .submit-btn[data-v-74636682] {
  width: 6.4rem;
  height: 0.88rem;
  margin: 0 auto;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 0.5rem;
  overflow: hidden;
}
.page .submit-btn[data-v-74636682]:active {
  opacity: 0.7;
}
.page .alert-container[data-v-74636682] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.page .alert-container .alert-content[data-v-74636682] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.page .protocol-alert .alert-content[data-v-74636682] {
  top: 0.7rem;
}
.page .protocol-alert .alert-content .protocol-container[data-v-74636682] {
  width: 6.7rem;
  height: 8.86rem;
  margin-bottom: 0.4rem;
  background-color: #fff;
  border-radius: 0.08rem;
  border: 0.04rem solid var(--main-color);
  overflow: hidden;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box[data-v-74636682] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 6.5rem;
  height: 8.66rem;
  padding: 0.3rem 0.2rem;
  background-color: #fff;
  border-radius: 0.08rem;
  border: 0.04rem solid var(--main-color);
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .top-title[data-v-74636682] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.76rem;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .top-title .dot[data-v-74636682] {
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  background-color: #999;
  border-radius: 50%;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .top-title .txt[data-v-74636682] {
  margin: 0 0.2rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.44rem;
  letter-spacing: -0.02rem;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .protocol-title[data-v-74636682] {
  margin-bottom: 0.6rem;
  font-size: 0.36rem;
  color: var(--main-color);
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
  font-weight: bold;
}
.page .protocol-alert .alert-content .protocol-container .protocol-box .protocol-content[data-v-74636682] {
  max-height: 60%;
  font-size: 0.26rem;
  color: #666;
  line-height: 0.44rem;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.page .protocol-alert .alert-content .close-btn[data-v-74636682] {
  width: 0.68rem;
  height: 0.68rem;
  margin: 0 auto;
  background-image: url('../../assets/icon_denglu_guanbi@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.page .protocol-alert .alert-content .close-btn[data-v-74636682] {
    background-image: url('../../assets/icon_denglu_guanbi@3x.png');
}
}
.page .login-text[data-v-74636682] {
  text-align: center;
  width: 100%;
  margin-top: 0.3rem;
}
