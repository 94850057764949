.border-none[data-v-a2246cb2]:before {
  display: none;
}
.border-none[data-v-a2246cb2]:after {
  display: none;
}
.flex-fix[data-v-a2246cb2] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-a2246cb2]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-a2246cb2] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-a2246cb2] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-a2246cb2] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-a2246cb2] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-a2246cb2] {
  *zoom: 1;
}
.clear-fix[data-v-a2246cb2]:before,
.clear-fix[data-v-a2246cb2]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.table[data-v-a2246cb2] {
  /*此元素会作为块级表格来显示（类似 <table>），表格前后带有换行符。*/
  display: table;
  /*border-collapse:collapse;*/
  border-collapse: separate;
  border: 1px solid #ccc;
}
.table-caption[data-v-a2246cb2] {
  /*此元素会作为一个表格标题显示（类似 <caption>）*/
  display: table-caption;
  margin: 0;
  font-size: 16px;
}
.table-header-group[data-v-a2246cb2] {
  /*此元素会作为一个或多个行的分组来显示（类似 <thead>）。*/
  display: table-header-group;
  font-weight: bold;
  font-size: 0.01rem;
}
.table-row-group[data-v-a2246cb2] {
  /*此元素会作为一个或多个行的分组来显示（类似 <tbody>）。*/
  display: table-row-group;
  font-size: 0.01rem;
}
.table-footer-group[data-v-a2246cb2] {
  /*此元素会作为一个或多个行的分组来显示（类似 <tfoot>）。*/
  display: table-footer-group;
}
ul[data-v-a2246cb2] {
  list-style: none;
}
.table-row[data-v-a2246cb2] {
  /*此元素会作为一个表格行显示（类似 <tr>）。*/
  display: table-row;
}
#top[data-v-a2246cb2] {
  border-top: 1px solid #ccc;
}
.table-cell[data-v-a2246cb2] {
  /*此元素会作为一个表格单元格显示（类似 <td> 和 <th>）*/
  display: table-cell;
  padding: 4px;
  border: 1px solid #ccc;
  line-height: 1.5;
  border-left: 0px;
  border-top: 0px;
}
.table-cell[data-v-a2246cb2]:first-child {
  border-left: 1px solid #ccc;
}
.table-row-group .table-row[data-v-a2246cb2]:hover,
.table-footer-group .table-row[data-v-a2246cb2]:hover {
  background: #f6f6f6;
  color: green;
  font-weight: bold;
}
.table-column-group[data-v-a2246cb2] {
  /*此元素会作为一个或多个列的分组来显示（类似 <colgroup>）。*/
  display: table-column-group;
}
.table-column[data-v-a2246cb2] {
  /*此元素会作为一个单元格列显示（类似 <col>）*/
  display: table-column;
  width: 100px;
}
.agreement[data-v-a2246cb2] {
  height: 8.66rem;
}
.agreement .agreement-con[data-v-a2246cb2] {
  height: 8.2rem;
  overflow: auto;
}
.agreement .agreement-con p[data-v-a2246cb2] {
  margin-bottom: 0.2rem;
  line-height: 1.5;
}
.agreement .agreement-con .protocol-title[data-v-a2246cb2] {
  text-align: center;
  margin-bottom: 0.6rem;
  font-size: 0.36rem;
  color: var(--main-color);
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
  font-weight: bold;
}
.agreement .agreement-con .top-title[data-v-a2246cb2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0.76rem;
  text-align: center;
}
.agreement .agreement-con .top-title .dot[data-v-a2246cb2] {
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  background-color: #999;
  border-radius: 50%;
}
.agreement .agreement-con .top-title .txt[data-v-a2246cb2] {
  margin: 0 0.2rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.44rem;
  letter-spacing: -0.02rem;
}
